"use client";

import React, { ReactNode, useMemo, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SvgIconProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import MhcTooltip from "./MhcTooltip";

const infoIconVariantsArray = ["standard", "cpr", "dark"] as const;

export type InfoIconVariants = (typeof infoIconVariantsArray)[number];

export interface InfoIconProps extends SvgIconProps {
  IconComponent?: React.ComponentType<SvgIconProps>;
  children: ReactNode;
  variant?: InfoIconVariants;
  lightVariant?: boolean;
  hideArrow?: boolean;
}

export const InfoIcon: React.FC<InfoIconProps> = ({
  children,
  IconComponent = InfoOutlinedIcon,
  variant = "standard",
  lightVariant,
  hideArrow,
  ...iconProps
}) => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { defaultColor, hoverColor } = useMemo(() => {
    let defaultColor = variant === "standard" ? palette.primary.main : palette.light.secondary;
    if (variant === "cpr") {
      defaultColor = palette.light.secondary;
    }
    if (variant === "dark") {
      defaultColor = "rgba(0, 0, 0, 0.50)";
    }
    let hoverColor = palette.primary.dark;
    if (variant === "cpr" || variant === "dark") {
      hoverColor = palette.light.primary;
    }
    return { defaultColor, hoverColor };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  return (
    <MhcTooltip
      lightVariant={lightVariant}
      title={children ?? ""}
      label="Information"
      arrow={hideArrow ? undefined : true}
      open={showTooltip}
      disableHoverListener={isMobile}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <IconComponent
        fontSize="inherit"
        {...iconProps}
        tabIndex={0}
        onClick={isMobile ? () => setShowTooltip(!showTooltip) : undefined}
        sx={{ fill: defaultColor, "&:hover": { fill: hoverColor }, ...iconProps.sx }}
      />
    </MhcTooltip>
  );
};
